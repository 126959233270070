/**
 *
 * The CPF (Cadastro de Pessoas Físicas) is the Brazilian identification number
 * assigned to individuals for tax purposes. The number consists of 11 digits
 * and includes two check digits.
 *
 */

import { t } from 'bv-i18n';

const REGEX = /^[0-9]{11}$/;

const computeCheckSum = (input) => {
  const mlen = input.length + 1;

  const value = input
    .split('')
    .map((v, idx) => parseInt(v, 10) * (mlen - idx))
    .reduce((acc, v) => (acc + v) % 11);

  return value < 2 ? 0 : 11 - value;
};

const validateBrazilCPFNumber = () => (
  (value) => {
    if (!REGEX.test(value)) {
      return t('javascript.error_identity_number_format');
    }

    const checkSum1 = computeCheckSum(value.substr(0, 9));
    const checkSum2 = computeCheckSum(value.substr(0, 10));

    if (
      checkSum1 === parseInt(value.charAt(9), 10)
      && checkSum2 === parseInt(value.charAt(10), 10)
    ) {
      return undefined;
    }

    return t('javascript.error_identity_number_format');
  }
);

const validateBrazilCPFNumberNotMandatory = () => (
  (value) => (value ? validateBrazilCPFNumber()(value) : undefined)
);

export {
  validateBrazilCPFNumber,
  validateBrazilCPFNumberNotMandatory,
};

import { t } from 'bv-i18n';
import validateObject from '../helpers/validate_object';

const validString = (min) => (value) => (Boolean(value && value.trim().length >= min));

export default (minLength, errorMessage) => (
  (value) => {
    const message = errorMessage || t('javascript.account_form.errors.minimum_characters', { min_length: minLength });
    if (value === undefined || value === null) return message;

    switch (typeof value) {
      case 'object':
        return value && validateObject(value, validString(minLength)) ? undefined : message;
      default:
        return validString(minLength)(value) ? undefined : message;
    }
  }
);

import requestStrategy from '../api/verify_personal_details';

export const OPEN_DOCUMENT_UPLOADER = 'personalDetails/OPEN_DOCUMENT_UPLOADER';
export const OPEN_RESULT_MESSAGE = 'personalDetails/OPEN_RESULT_MESSAGE';
export const LOADING_STRATEGY = 'personalDetails/LOADING_STRATEGY';
export const RESET_MODAL_PROPERTIES = 'personalDetails/RESET_MODAL_PROPERTIES';

export const loadingStrategy = () => ({
  type: LOADING_STRATEGY,
});

export const openDocumentUploader = (data) => ({
  type: OPEN_DOCUMENT_UPLOADER,
  dataStrategyIframe: data.url,
});

export const openResultMessage = (data) => ({
  type: OPEN_RESULT_MESSAGE,
  dataStrategyMessage: data.flash,
});

export const resetModalProperties = () => ({
  type: RESET_MODAL_PROPERTIES,
});

const template = (responseTemplate) => (
  {
    iframe: openDocumentUploader,
    message: openResultMessage,
  }[responseTemplate]
);

export const startStrategy = (strategy) => (dispatch) => {
  dispatch(loadingStrategy());

  requestStrategy(strategy).then(((response) => {
    dispatch(template(response.template)(response.data));
  }));
};

const initialState = {
  loadingStrategy: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case OPEN_DOCUMENT_UPLOADER:
      return {
        ...state,
        showStrategyIframe: true,
        dataStrategyIframe: action.dataStrategyIframe,
        loadingStrategy: false,
      };
    case OPEN_RESULT_MESSAGE:
      return {
        ...state,
        showStrategyMessage: true,
        dataStrategyMessage: { ...state.dataStrategyMessage, ...action.dataStrategyMessage },
        loadingStrategy: false,
      };
    case RESET_MODAL_PROPERTIES:
      return {
        ...state,
        showStrategyMessage: false,
      };
    case LOADING_STRATEGY:
      return {
        ...state,
        loadingStrategy: true,
      };
    default:
      return state;
  }
};

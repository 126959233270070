const validString = (value) => (Boolean(value && value.toString().trim()));

const validObj = (obj) => (
  Object.keys(obj).find((k) => validString(obj[k]) === false) === undefined
);

export default (errorMessage) => (
  (value) => {
    switch (typeof value) {
      case 'object':
        return value && validObj(value) ? undefined : errorMessage;
      default:
        return validString(value) ? undefined : errorMessage;
    }
  }
);

import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const CodeSentFeedback = ({ cooldown }) => (
  <p>
    { t('javascript.twofa.send_code_again.send_success_feedback', { cooldown_seconds: cooldown }) }
  </p>
);

CodeSentFeedback.propTypes = {
  cooldown: PropTypes.number.isRequired,
};

export default CodeSentFeedback;

import PropTypes from 'prop-types';
import { Button, RenderInModal, SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';

const SubmitButton = ({ disabled, onClick, submitting }) => (
  <RenderInModal className="personal-details__submit-wrapper">
    <Button
      type="submit"
      primary
      disabled={disabled}
      onClick={onClick}
    >
      {t('javascript.update')}
      {submitting && <SmallSpinner primary />}
    </Button>
  </RenderInModal>
);

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default SubmitButton;

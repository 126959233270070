import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { getValidationFunction } from 'validation-helpers';
import { t } from 'bv-i18n';
import getField from '../../helpers/address_helpers';

const EditAddressFields = ({
  addressFields, countryZones, userCountryName,
}) => (
  <div>
    {addressFields.map(({
      name, label, value, validations,
    }) => (
      <Field
        key={name}
        component={getField(name)}
        type="text"
        name={name}
        label={label}
        initialValue={value}
        validate={getValidationFunction(validations)}
        countryZones={countryZones}
      />
    ))}
    {userCountryName && (
      <span>
        <dt className="pd-list__label">{t('country')}</dt>
        <dd className="pd-list__value">{userCountryName}</dd>
      </span>
    )}
  </div>
);

EditAddressFields.propTypes = {
  addressFields: PropTypes.arrayOf(Object).isRequired,
  userCountryName: PropTypes.string,
  countryZones: PropTypes.arrayOf(Object).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

EditAddressFields.defaultProps = {
  userCountryName: null,
};

export default EditAddressFields;

import { t } from 'bv-i18n';

const USERNAME_REGEX = /^([A-Za-z0-9._]+){6,}$/;
const EMAIL_ADDRESS_REGEX = /^(?!.*([.+/|\-^&*_#!$%=?~{}])([.+/|\-^&*_#!$%=?~{}])*\1)[a-zA-Z0-9.+/|\-^&*_#!$%=?~{}]+$/;
const EMAIL_DOMAIN_REGEX = /^(?!.*(\.)(\.)*\1)[a-zA-Z0-9+\-&.]+[^.]$/;

const validateEmailRegex = (value) => {
  if ((value.match(/@/g) || []).length !== 1) return false;
  const [address, domain] = value.split('@');
  return EMAIL_ADDRESS_REGEX.test(address) && EMAIL_DOMAIN_REGEX.test(domain);
};

export default (errorMessage) => (
  (value) => (
    !value || (!USERNAME_REGEX.exec(value) && !validateEmailRegex(value))
      ? errorMessage || t('javascript.account_form.errors.enter_valid_username_or_email')
      : undefined
  )
);

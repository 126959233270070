import PropTypes from 'prop-types';
import { map, range } from 'underscore';
import classNames from 'classnames';
import Dot from './dot';

const DotContainer = ({ code, status }) => {
  const getDigitAtPosition = (allCode, index) => (allCode.length > index ? allCode.substr(index, 1) : '');

  const renderDots = (first, last) => map(range(first, last), (position) => (
    <Dot value={getDigitAtPosition(code, position)} status={status} key={position} />
  ));

  return (
    <div className="send-verify-code__feedback" id="two_factor_code_feedback_div">
      {renderDots(0, 3)}
      <div className={classNames('send-verify-code__feedback-dash', {
        [`send-verify-code__feedback-dash--${status}`]: status,
      })}
      >
        &#x2015;
      </div>
      {renderDots(3, 6)}
    </div>
  );
};

DotContainer.propTypes = {
  code: PropTypes.string,
  status: PropTypes.string,
};

DotContainer.defaultProps = {
  code: '',
  status: '',
};

export default DotContainer;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'bv-i18n';
import withPersonalDetailsSection from 'PersonalDetails/hocs/with_personal_details_section';
import {
  getAddressFields,
  countryName as getCountryName,
  getCountryZone,
} from 'SharedComponents/personal_details/ducks';
import EditButton from 'PersonalDetails/components/utils/edit_button';

const AddressDetails = ({ addressFields, countryZone, countryName }) => (
  <>
    <dl className="pd-list">
      <dt className="pd-list__label">{t('javascript.personal_details.user_details.address')}</dt>
      <dd className="pd-list__value">
        {addressFields.map(({ value, name }) => (
          <span key={name} className="pd-list__value-line">
            {['county_id', 'state_id'].includes(name) ? countryZone : value}
          </span>
        ))}
      </dd>
      <dt className="pd-list__label">{t('javascript.personal_details.user_details.country')}</dt>
      <dd className="pd-list__value">{countryName}</dd>
    </dl>
    <EditButton
      href="/personal_details/address"
      label={t('javascript.personal_details.edit_button')}
    />
  </>
);

AddressDetails.propTypes = {
  addressFields: PropTypes.arrayOf(Object).isRequired,
  countryName: PropTypes.string,
  countryZone: PropTypes.string.isRequired,
};

AddressDetails.defaultProps = {
  countryName: '',
};

const mapStateToProps = (state) => ({
  addressFields: getAddressFields(state),
  countryName: getCountryName(state),
  countryZone: getCountryZone(state),
});

export default connect(mapStateToProps)(
  withPersonalDetailsSection({
    className: 'address-details',
  })(AddressDetails),
);

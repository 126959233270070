import validateObject from '../helpers/validate_object';

const validString = (value) => !/[0-9]/.test(value);

export default (errorMessage) => (
  (value) => {
    if (value === undefined || value === null) return value;

    switch (typeof value) {
      case 'object':
        return validateObject(value, validString) ? undefined : errorMessage;
      default:
        return validString(value) ? undefined : errorMessage;
    }
  }
);

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { Spinner } from 'bv-components';
import { isEmpty } from 'underscore';
import { useHistory } from 'react-router-dom';
import EditPhoneNumberView from './edit_phone_number_view';

const EditPhoneNumberContainer = ({
  handleSubmitBtnClick, twoFASmsEnabled, countries, fetchMobileNumberCountries, pristine,
  detailedPhoneNumber, handleSubmit, submitting,
  submitSucceeded, setPersonalDetailsUpdated,
}) => {
  const history = useHistory();
  const forbiddenEditing = bvVar('forbiddenPhoneNumberEditing');

  useEffect(() => {
    if (!forbiddenEditing) {
      fetchMobileNumberCountries();
    }
  }, []);

  if (forbiddenEditing) {
    history.replace('/personal_details');
    return null;
  }

  if (isEmpty(countries)) return <Spinner />;

  const onSuccessModalClose = () => {
    setPersonalDetailsUpdated();
    history.push('/personal_details');
  };

  return (
    <EditPhoneNumberView
      countries={countries}
      handleSubmitBtnClick={handleSubmitBtnClick}
      twoFASmsEnabled={twoFASmsEnabled}
      handleSubmit={handleSubmit}
      submitting={submitting}
      submitSucceeded={submitSucceeded}
      pristine={pristine}
      onSuccessModalClose={onSuccessModalClose}
      detailedPhoneNumber={detailedPhoneNumber}
    />
  );
};

EditPhoneNumberContainer.propTypes = {
  handleSubmitBtnClick: PropTypes.func.isRequired,
  fetchMobileNumberCountries: PropTypes.func.isRequired,
  twoFASmsEnabled: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(Object).isRequired,
  detailedPhoneNumber: PropTypes.instanceOf(Object).isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  setPersonalDetailsUpdated: PropTypes.func.isRequired,
};

export default EditPhoneNumberContainer;

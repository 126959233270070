import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import classnames from 'classnames';

const VerificationStatus = ({ verified }) => (
  <div
    className={classnames('pd-verification-status', {
      'bvs-text-success': verified,
      'bvs-text-error': !verified,
    })}
  >
    <Icon id={verified ? 'check' : 'close'} />
    {t(`javascript.personal_details.${verified ? 'verified' : 'unverified'}`)}
  </div>
);

VerificationStatus.propTypes = {
  verified: PropTypes.bool.isRequired,
};

export default VerificationStatus;

import PropTypes from 'prop-types';
import VerificationStatus from './verification_status';

const SectionFooter = ({ verified, verificationButton }) => (
  <>
    <hr />
    <div className="pd-footer">
      <VerificationStatus verified={verified} />
      {verificationButton}
    </div>
  </>
);

SectionFooter.propTypes = {
  verified: PropTypes.bool.isRequired,
  verificationButton: PropTypes.element.isRequired,
};

export default SectionFooter;

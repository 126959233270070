import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import {
  IntroText,
  DotContainer,
  CodeInput,
  SendCodeSpinner,
  SendAgain,
  SendAgainControl,
  CodeStatus,
} from 'SharedComponents/send_code_from_sms';
import {
  CODE_WAS_SENT_TOO_MANY_TIMES,
  SUCCESS,
  MAX_CODE_LENGTH,
  isValidStatus,
  isInvalidStatus,
} from 'PersonalDetails/helpers/verification_constants';

const MobileVerificationView = ({
  focusCodeInput, loading, status, codeValue, triesLeft, codeInputRef, inputValue, wasCodeResent,
  handleCodeInputChange, handleCodeInputKeyPress, coolDown, showErrorModal, setShowErrorModal,
  resendLinkDisabled, handleSendAgainButtonClick,
}) => (
  <>
    <div className="send-verify-code" onClick={focusCodeInput}>
      { loading && <SendCodeSpinner /> }
      <IntroText
        description={t('javascript.twofa.code_description')}
        enterCode={t('javascript.twofa.enter_sms_code')}
        showTrustCheckbox={false}
        icon={<div className="send-verify-code__header-icon" />}
      />
      <DotContainer status={status} code={codeValue} />
      <CodeStatus
        status={status}
        triesLeft={triesLeft}
        isValidStatus={isValidStatus}
        isInvalidStatus={isInvalidStatus}
      />
      <CodeInput
        ref={codeInputRef}
        maxCodeLength={MAX_CODE_LENGTH}
        value={inputValue}
        handleCodeInputChange={handleCodeInputChange}
        handleCodeInputKeyPress={handleCodeInputKeyPress}
      />
      {
        ![SUCCESS, CODE_WAS_SENT_TOO_MANY_TIMES].includes(status) && (
          <SendAgain
            method={null}
            wasCodeResent={wasCodeResent}
            cooldown={coolDown}
            control={(
              <SendAgainControl
                disabled={resendLinkDisabled}
                handleClick={handleSendAgainButtonClick}
              />
            )}
          />
        )
      }
    </div>
    { showErrorModal && (
      <Modal
        danger
        actions={[
          {
            label: t('javascript.twofa.settings.ok_button'),
            danger: true,
            close: true,
            onClick: () => setShowErrorModal(false),
          },
        ]}
      >
        <p>{t('javascript.twofa.error.general')}</p>
      </Modal>
    )}
  </>
);

MobileVerificationView.propTypes = {
  focusCodeInput: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  codeValue: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  triesLeft: PropTypes.number.isRequired,
  codeInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  inputValue: PropTypes.string.isRequired,
  handleCodeInputChange: PropTypes.func.isRequired,
  handleCodeInputKeyPress: PropTypes.func.isRequired,
  wasCodeResent: PropTypes.bool.isRequired,
  coolDown: PropTypes.number.isRequired,
  resendLinkDisabled: PropTypes.bool.isRequired,
  handleSendAgainButtonClick: PropTypes.func.isRequired,
  showErrorModal: PropTypes.bool.isRequired,
  setShowErrorModal: PropTypes.func.isRequired,
};

export default MobileVerificationView;

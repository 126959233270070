import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Card } from 'bv-components';
import EditButton from '../../utils/edit_button';

const OccupationAndSalaryView = ({
  occupation, salary, required, editable,
}) => {
  if (required && occupation && salary) {
    return (
      <Card className="occupation-and-salary">
        <dl className="pd-list">
          <dt className="pd-list__label">{t('javascript.personal_details.occupation')}</dt>
          <dd className="pd-list__value">{occupation}</dd>
          <dt className="pd-list__label">{t('javascript.personal_details.salary')}</dt>
          <dd className="pd-list__value">{salary}</dd>
        </dl>
        {editable && (
          <EditButton
            href="/personal_details/occupation_and_salary"
            label={t('javascript.personal_details.edit_button')}
          />
        )}
      </Card>
    );
  }

  return null;
};

OccupationAndSalaryView.propTypes = {
  occupation: PropTypes.string,
  salary: PropTypes.string,
  required: PropTypes.bool,
  editable: PropTypes.bool,
};

OccupationAndSalaryView.defaultProps = {
  occupation: null,
  salary: null,
  required: false,
  editable: false,
};

export default OccupationAndSalaryView;

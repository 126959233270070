export const DEFAULT_COOLDOWN_VALUE = 60;
export const BACKSPACE_KEY_CODE = 8;
export const MAX_CODE_LENGTH = 6;

export const ERROR_CODES = {
  WRONG_CODE: 'MV-002',
  CODE_WAS_EXPIRED: 'MV-003',
  CODE_WAS_SENT_TOO_MANY_TIMES: 'MV-004',
};

export const WRONG_CODE = 'wrong_code';
export const FAILED = 'failed';
export const CODE_WAS_SENT_TOO_MANY_TIMES = 'code_was_sent_too_many_times';
export const SUCCESS = 'success';

export const isValidStatus = (status) => [SUCCESS].includes(status);
export const isInvalidStatus = (status) => (
  [WRONG_CODE, FAILED, CODE_WAS_SENT_TOO_MANY_TIMES].includes(status)
);

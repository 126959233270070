import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'bv-i18n';
import withPersonalDetailsSection from 'PersonalDetails/hocs/with_personal_details_section';
import { generalDetails as getGeneralDetails } from 'SharedComponents/personal_details/ducks';

const GeneralDetails = ({ generalDetails }) => (
  <dl className="pd-list">
    <dt className="pd-list__label">{t('javascript.personal_details.user_details.first_name')}</dt>
    <dd className="pd-list__value">{generalDetails.firstName}</dd>
    <dt className="pd-list__label">{t('javascript.personal_details.user_details.surname')}</dt>
    <dd className="pd-list__value">{generalDetails.lastName}</dd>
    <dt className="pd-list__label">{t('javascript.personal_details.user_details.dob')}</dt>
    <dd className="pd-list__value">{generalDetails.dateOfBirth}</dd>
    <dt className="pd-list__label">{t('javascript.personal_details.user_details.username')}</dt>
    <dd className="pd-list__value">{generalDetails.username}</dd>
  </dl>
);

GeneralDetails.propTypes = {
  generalDetails: PropTypes.instanceOf(Object),
};

GeneralDetails.defaultProps = {
  generalDetails: {},
};

const mapStateToProps = (state) => ({
  generalDetails: getGeneralDetails(state),
});

export default connect(mapStateToProps)(
  withPersonalDetailsSection({
    className: 'general-details',
  })(GeneralDetails),
);

import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Card } from 'bv-components';
import updatePersonalDetails from 'SharedComponents/personal_details/api/update_personal_details';
import EditAddressFields from 'SharedComponents/personal_details/components/edit_address/edit_address_fields';
import { t } from 'bv-i18n';
import SubmitButton from '../utils/submit_button';
import SuccessSubmitModal from '../success_submit_modal';
import FailureSubmitModal from '../failure_submit_modal';

const EditAddressForm = ({
  userCountryName, setPersonalDetailsUpdated, countryZones, addressFields, history,
}) => (
  <Card className="personal_details__form">
    <Form
      id="update-address-form"
      onSubmit={updatePersonalDetails}
    >
      {({
        submitError, pristine, submitting, handleSubmit, submitSucceeded,
        hasValidationErrors, form,
      }) => (
        <>
          <form>
            <EditAddressFields
              addressFields={addressFields}
              countryZones={countryZones}
              userCountryName={userCountryName}
            />
            <SubmitButton
              disabled={pristine || hasValidationErrors || submitting}
              onClick={handleSubmit}
              submitting={submitting}
            />
          </form>
          {submitSucceeded && (
            <SuccessSubmitModal
              message={t('javascript.address_updated')}
              onClose={() => {
                setPersonalDetailsUpdated();
                history.push('/personal_details');
              }}
            />
          )}
          {submitError && (
            <FailureSubmitModal
              message={submitError}
              onClose={() => {
                const { values } = form.getState();
                form.reset();
                form.batch(() => {
                  Object.entries(values).forEach(([key, value]) => {
                    form.change(key, value);
                  });
                });
              }}
            />
          )}
        </>
      )}
    </Form>
  </Card>
);

EditAddressForm.propTypes = {
  addressFields: PropTypes.arrayOf(Object).isRequired,
  userCountryName: PropTypes.string.isRequired,
  setPersonalDetailsUpdated: PropTypes.func.isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default EditAddressForm;

import { connect } from 'react-redux';
import { compose } from 'underscore';
import {
  countryName,
  getAddressFields,
  getCountryZones,
  getInitialAddressValues,
  setPersonalDetailsUpdated,
} from 'SharedComponents/personal_details/ducks';
import EditAddressContainer from './edit_address_container';

const mapStateToProps = (state) => ({
  initialValues: getInitialAddressValues(state),
  addressFields: getAddressFields(state),
  userCountryName: countryName(state),
  countryZones: getCountryZones(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPersonalDetailsUpdated: compose(dispatch, setPersonalDetailsUpdated),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressContainer);

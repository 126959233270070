import { Card } from 'bv-components';

export default (sectionProps) => (WrappedComponent) => {
  const withPersonalDetailsSection = (props) => (
    <Card {...sectionProps}>
      <WrappedComponent {...props} />
    </Card>
  );
  return withPersonalDetailsSection;
};

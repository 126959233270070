import { t } from 'bv-i18n';
import { getCaptchaService } from 'bv-services';
import { emailAvailability } from '../api/email_validation';

const performValidation = ({ email, emailsInUse }, form, token) => (
  emailAvailability(email, token)
    .then((response) => {
      form.change('emailAvailable', {
        ...response,
        value: email,
      });
      if (response.valid) {
        return Promise.resolve(true);
      }
      form.change('emailsInUse', [...(emailsInUse || []), email]);
      throw new Error(response.message || t('javascript.account_form.errors.general'));
    })
);

export default async (values, form) => {
  const { email, emailAvailable } = values;

  if (emailAvailable && emailAvailable.value === email) {
    return Promise.resolve(emailAvailable);
  }

  const token = await getCaptchaService().execute('email_available');
  return performValidation(values, form, token);
};

import { connect } from 'react-redux';
import {
  startStrategy,
  resetModalProperties,
} from '../../../ducks/email_verification';
import { showEmailMessage } from '../../../selectors/email_verification';
import EmailDetailsView from './email_details_view';

const mapStateToProps = (state) => {
  const { personalDetails: { accountData } } = state;

  return {
    email: accountData.email,
    emailVerified: accountData.emailVerified,
    showEmailMessage: showEmailMessage(state),
  };
};

export default connect(mapStateToProps, {
  startStrategy,
  resetModalProperties,
})(EmailDetailsView);

import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const TrustCheckbox = ({ checked, onChange }) => (
  <div className="send-verify-code__trust-checkbox">
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    { t('javascript.twofa.remember_device') }
  </div>
);

TrustCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TrustCheckbox;

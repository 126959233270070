import { t } from 'bv-i18n';
import validateDateOfBirth from './validate_date_of_birth';
import validAge from './age_equal_or_greater';
import validMaxAge from './age_not_greater_than';

const REGEX_ONE = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}(\d|x|X)$/;
const REGEX_TWO = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/;

const validateCheckSum = (value) => {
  const checkSums = [1, 0, 'x', 9, 8, 7, 6, 5, 4, 3, 2];
  const digits = value.toString().split('');
  const checkDigits = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const sum = checkDigits.reduce(
    (accumulator, digit, index) => accumulator + (digit * parseInt(digits[index], 10)), 0,
  );

  const checkSum = checkSums[sum % 11];
  return digits[17].toUpperCase() === checkSum.toString().toUpperCase();
};

const validDateOfBirth = (date) => (
  validateDateOfBirth(t('javascript.account_form.errors.invalid_date'))(date)
  || validAge(18, t('javascript.account_form.errors.age_limitation'))(date)
  || validMaxAge(110, t('javascript.account_form.errors.age_limitation'))(date)
);

export default () => (
  (value) => {
    if ((REGEX_ONE.exec(value) && validateCheckSum(value)) || REGEX_TWO.exec(value)) {
      const dateOfBirth = `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`;
      return validDateOfBirth(dateOfBirth);
    }

    return t('javascript.error_identity_number_format');
  }
);

import { validateMobileNumberApi } from 'validations';
import { putJSON } from 'bv-fetch';
import { FORM_ERROR } from 'final-form';
import { t } from 'bv-i18n';
import { omit } from 'underscore';

const submissionError = (error) => ({
  [FORM_ERROR]: error || t('errors_something_wrong'),
});

export default async (values) => {
  const { phone_number: phoneNumber } = values;
  const formValues = omit(values, 'phone_number');
  try {
    if (phoneNumber) {
      const { valid, number, message } = await validateMobileNumberApi(
        phoneNumber.number, phoneNumber.country_iso,
      );
      if (!valid) {
        return { phone_number: message };
      }
      formValues.mobile = number;
    }
    const result = await putJSON('/personal_details', { account: formValues });
    if (!result.success) return submissionError(result.error);
    return undefined;
  } catch (e) {
    return submissionError();
  }
};

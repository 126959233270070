import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import classnames from 'classnames';
import DialCodes from './dial_codes';

const PhoneNumberView = ({
  input, label, countries, currentCountry, countryDialSearch, onDialSearch, meta: {
    touched, error, active, submitError,
  }, onCountryClick, onChange, showDialCodes, onDialListClick, onDialListBlur, onBlur,
}) => {
  const { errors: validationErrors } = useFormState();

  return (
    <div className="account-form__fields account-form__fields--mobile-number">
      <DialCodes
        countries={countries}
        showDialCodes={showDialCodes}
        currentCountry={currentCountry}
        onDialListClick={onDialListClick}
        onDialListBlur={onDialListBlur}
        onCountryClick={onCountryClick}
        countryDialSearch={countryDialSearch}
        onDialSearch={onDialSearch}
      />
      <div className="bvs-form-group mobile-number-wrapper">
        <input
          {...input}
          id={input.name}
          type="tel"
          className={classnames('bvs-form-control', {
            error: (error || submitError) && touched,
            active,
            'with-value': !!(input.value?.number),
          })}
          autoCapitalize="off"
          autoCorrect="off"
          value={input.value && input.value.number}
          onChange={onChange}
          onBlur={onBlur}
        />
        <label className="bvs-form-control-placeholder" htmlFor={input.name}>{label}</label>
        { touched && !!(validationErrors.phone_number || submitError)
          && <span className="bvs-form-error-msg">{validationErrors.phone_number || submitError}</span> }
      </div>
    </div>
  );
};

PhoneNumberView.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.arrayOf(Object).isRequired,
  showDialCodes: PropTypes.bool.isRequired,
  currentCountry: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onDialListClick: PropTypes.func.isRequired,
  onDialListBlur: PropTypes.func.isRequired,
  onCountryClick: PropTypes.func.isRequired,
  countryDialSearch: PropTypes.string,
  onDialSearch: PropTypes.func.isRequired,
};

PhoneNumberView.defaultProps = {
  countryDialSearch: null,
};

export default PhoneNumberView;

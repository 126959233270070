import { postJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';
import { accountNumber } from 'bv-helpers/session';

export default ({ verificationToken }) => (
  postJSON(`/security_checks/${locale()}/mobile_number_verification`, {
    account_number: accountNumber(),
    verification_token: verificationToken,
  })
);

import { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { ButtonCard, Modal } from 'bv-components';
import { v as bvVar } from 'bv';
import withPersonalDetailsSection from '../../../hocs/with_personal_details_section';
import SectionFooter from '../section_footer';

const EmailDetailsView = ({
  email,
  emailVerified,
  startStrategy,
  showEmailMessage,
  resetModalProperties,
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(showEmailMessage);

  const closeModal = () => {
    resetModalProperties();
    setShowSuccessModal(false);
  };

  return (
    <>
      <dl className="pd-list">
        <dt className="pd-list__label">
          {t('javascript.personal_details.user_details.email')}
        </dt>
        <dd className="pd-list__value">{email}</dd>
      </dl>
      <SectionFooter
        verified={emailVerified}
        verificationButton={
          !emailVerified ? (
            <ButtonCard
              label={t('javascript.personal_details.verify_button')}
              type="personal-details-footer-button"
              onClick={() => startStrategy(bvVar('kyc').strategies.email_verification)}
            />
          ) : null
        }
      />
      {showEmailMessage && showSuccessModal && (
        <Modal
          title={t('javascript.personal_details.email_verification.title')}
          success
          actions={[{
            id: 'ok-btn',
            label: t('close'),
            primary: true,
            close: true,
            onClick: closeModal,
          }]}
        >
          <p>
            {t('javascript.personal_details.email_verification.body')}
          </p>
        </Modal>
      )}
    </>
  );
};

EmailDetailsView.propTypes = {
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  showEmailMessage: PropTypes.bool,
  startStrategy: PropTypes.func.isRequired,
  resetModalProperties: PropTypes.func.isRequired,
};

EmailDetailsView.defaultProps = {
  email: '',
  emailVerified: false,
  showEmailMessage: false,
};

export default withPersonalDetailsSection({
  className: 'email-details',
})(EmailDetailsView);

import {
  FETCH_PERSONAL_DETAILS_COMPLETE,
  FETCH_PERSONAL_DETAILS_INIT,
  SET_PERSONAL_DETAILS_UPDATED,
  UNSET_PERSONAL_DETAILS_UPDATED,
  SET_MOBILE_NUMBER_VERIFIED,
  FETCH_MOBILE_COUNTRIES_INIT,
  FETCH_MOBILE_COUNTRIES_COMPLETE,
} from './action_types';

export const initialState = {
  fetchingAccountDetails: false,
  accountData: {},
  personalDetailsUpdated: false,
  loadingMobileCountries: false,
  mobileNumberCountries: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PERSONAL_DETAILS_INIT:
      return {
        ...state,
        fetchingAccountDetails: true,
      };
    case FETCH_PERSONAL_DETAILS_COMPLETE:
      return {
        ...state,
        fetchingAccountDetails: false,
        accountData: action.accountData,
      };
    case SET_PERSONAL_DETAILS_UPDATED:
      return {
        ...state,
        personalDetailsUpdated: true,
      };
    case UNSET_PERSONAL_DETAILS_UPDATED:
      return {
        ...state,
        personalDetailsUpdated: false,
      };
    case SET_MOBILE_NUMBER_VERIFIED:
      return {
        ...state,
        accountData: {
          ...state.accountData,
          phoneNumberVerified: true,
        },
      };
    case FETCH_MOBILE_COUNTRIES_INIT:
      return {
        ...state,
        loadingMobileCountries: true,
      };
    case FETCH_MOBILE_COUNTRIES_COMPLETE:
      return {
        ...state,
        loadingMobileCountries: false,
        mobileNumberCountries: action.countries,
      };
    default:
      return state;
  }
};

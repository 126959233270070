import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Spinner } from 'bv-components';
import { useHistory } from 'react-router-dom';
import EditAddressView from './edit_address_view';

const EditAddressContainer = ({
  userCountryName, initialValues, addressFields, setPersonalDetailsUpdated, countryZones,
}) => {
  const history = useHistory();

  if (isEmpty(addressFields)) return <Spinner />;

  return (
    <EditAddressView
      initialValues={initialValues}
      history={history}
      addressFields={addressFields}
      userCountryName={userCountryName}
      setPersonalDetailsUpdated={setPersonalDetailsUpdated}
      countryZones={countryZones}
    />
  );
};

EditAddressContainer.propTypes = {
  addressFields: PropTypes.arrayOf(Object).isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  userCountryName: PropTypes.string.isRequired,
  setPersonalDetailsUpdated: PropTypes.func.isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
};

export default EditAddressContainer;

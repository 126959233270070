import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import handleRedirect from 'PersonalDetails/helpers/handle_redirect';
import {
  MAX_CODE_LENGTH,
  BACKSPACE_KEY_CODE,
  DEFAULT_COOLDOWN_VALUE,
  CODE_WAS_SENT_TOO_MANY_TIMES,
  FAILED,
  SUCCESS,
  WRONG_CODE,
  ERROR_CODES,
} from 'PersonalDetails/helpers/verification_constants';
import MobileVerificationView from './mobile_verification_view';

const MobileVerificationContainer = ({
  loading, verifyMobileNumber, resendMobileVerificationCode, setMobileNumberVerified,
}) => {
  const [status, setStatus] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [triesLeft, setTriesLeft] = useState(0);
  const [wasCodeResent, setWasCodeResent] = useState(false);
  const [resendLinkDisabled, setResendLinkDisabled] = useState(false);
  const [coolDown, setCoolDown] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const codeInputRef = useRef();
  const history = useHistory();

  const focusCodeInput = () => {
    if (codeInputRef?.current) codeInputRef.current.focus();
  };

  useEffect(() => { focusCodeInput(); });

  const getFullCode = (value) => {
    if (!retrying) return value.slice(0, MAX_CODE_LENGTH);

    setStatus('');
    setRetrying(false);

    return value.slice(-1);
  };

  const handleCodeInputKeyPress = ({ keyCode }) => {
    if (keyCode !== BACKSPACE_KEY_CODE) return;

    if (codeValue.length === MAX_CODE_LENGTH) {
      setCodeValue('');
      setStatus('');
      setInputValue('');
    } else {
      setCodeValue(codeValue.slice(0, -1));
      setInputValue('');
    }
  };

  const handleCodeInputChange = ({ target: { value } }) => {
    if (disabled) return;

    const tmpValue = value.replace(/\D/g, '');
    const digit = tmpValue.slice(0, 1);
    const code = getFullCode(`${codeValue}${digit}`);

    setCodeValue(code);
    setInputValue('');

    if (code.length === MAX_CODE_LENGTH) {
      setDisabled(true);

      verifyMobileNumber(code, (response) => {
        let newStatus;
        if (response.success) {
          newStatus = SUCCESS;
          setStatus(SUCCESS);
        } else if (response.errorCode === ERROR_CODES.WRONG_CODE) {
          newStatus = WRONG_CODE;
          setDisabled(false);
          setStatus(WRONG_CODE);
          setTriesLeft(response.remainingAttempts);
          setRetrying(true);
        } else if (response.errorCode === ERROR_CODES.CODE_WAS_EXPIRED) {
          newStatus = FAILED;
          setDisabled(false);
          setStatus(FAILED);
          setRetrying(true);
        } else if (response.errorCode === ERROR_CODES.CODE_WAS_SENT_TOO_MANY_TIMES) {
          newStatus = CODE_WAS_SENT_TOO_MANY_TIMES;
          setDisabled(true);
          setStatus(CODE_WAS_SENT_TOO_MANY_TIMES);
        }

        handleRedirect(newStatus, history.push, setMobileNumberVerified);
      });
    }
  };

  const handleSendAgainButtonClick = () => {
    resendMobileVerificationCode((response) => {
      if (response.success) {
        setWasCodeResent(true);
        setResendLinkDisabled(true);
        let coolDownTime = DEFAULT_COOLDOWN_VALUE;
        setCoolDown(coolDownTime);

        const coolDownInterval = setInterval(() => {
          if (coolDownTime > 1) {
            coolDownTime -= 1;
            setCoolDown(coolDownTime);
          } else {
            clearInterval(coolDownInterval);
            setWasCodeResent(false);
            setResendLinkDisabled(false);
          }
        }, 1000);
      } else {
        setShowErrorModal(true);
      }
    });
  };

  return (
    <MobileVerificationView
      focusCodeInput={focusCodeInput}
      loading={loading}
      codeValue={codeValue}
      status={status}
      triesLeft={triesLeft}
      codeInputRef={codeInputRef}
      inputValue={inputValue}
      handleCodeInputChange={handleCodeInputChange}
      handleCodeInputKeyPress={handleCodeInputKeyPress}
      wasCodeResent={wasCodeResent}
      coolDown={coolDown}
      resendLinkDisabled={resendLinkDisabled}
      handleSendAgainButtonClick={handleSendAgainButtonClick}
      showErrorModal={showErrorModal}
      setShowErrorModal={setShowErrorModal}
    />
  );
};

MobileVerificationContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  resendMobileVerificationCode: PropTypes.func.isRequired,
  verifyMobileNumber: PropTypes.func.isRequired,
  setMobileNumberVerified: PropTypes.func.isRequired,
};

export default MobileVerificationContainer;

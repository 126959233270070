import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  enable as enableBodyScroll,
  disable as disableBodyScroll,
} from 'bv-services/body-scroll-manager';
import { getInitCountry } from '../../helpers/phone_number_utils';
import PhoneNumberView from './phone_number_view';

const PhoneNumberContainer = ({
  input, label, meta, countries, defaultMobileCountry,
}) => {
  const [currentCountry, setCurrentCountry] = useState(
    getInitCountry(input.value, countries, defaultMobileCountry),
  );

  useEffect(() => {
    if (!input.value) {
      input.onChange({
        country_iso: currentCountry.code,
        dial_code: currentCountry.dialCode,
      });
    }

    const countryListElement = document.getElementsByClassName('country-list')[0];
    disableBodyScroll(countryListElement);
    return () => enableBodyScroll(countryListElement);
  }, []);

  const [showDialCodes, setShowDialCodes] = useState(false);
  const [countryDialSearch, setCountryDialSearch] = useState(null);

  useEffect(() => {
    if (input.value && input.value.dial_code
      && input.value.dial_code !== currentCountry.dialCode
    ) {
      setCurrentCountry({
        code: input.value.country_iso,
        dialCode: input.value.dial_code,
      });
    }
  }, [input.value]);

  const onDialListClick = () => {
    if (!showDialCodes) {
      document.querySelector('.intl-tel-search-prefix').select();
    }
    setShowDialCodes(!showDialCodes);
  };

  const onDialListBlur = () => setShowDialCodes(false);

  const onCountryClick = (country) => {
    setCountryDialSearch(null);
    setCurrentCountry(country);
    input.onChange({
      ...input.value,
      country_iso: country.code,
      dial_code: country.dialCode,
    });
  };

  const onChange = ({ target }) => {
    input.onChange({
      number: target.value,
      country_iso: currentCountry.code,
      dial_code: currentCountry.dialCode,
    });
  };

  const onBlur = ({ target }) => {
    input.onBlur({
      number: target.value,
      country_iso: currentCountry.code,
      dial_code: currentCountry.dialCode,
    });
  };

  const onDialSearch = ({ target }) => {
    setCountryDialSearch(target.value);
    setShowDialCodes(true);
  };

  return (
    <PhoneNumberView
      input={input}
      label={label}
      meta={meta}
      currentCountry={currentCountry}
      onChange={onChange}
      onBlur={onBlur}
      countries={countries}
      showDialCodes={showDialCodes}
      onDialListClick={onDialListClick}
      onDialListBlur={onDialListBlur}
      onCountryClick={onCountryClick}
      countryDialSearch={countryDialSearch}
      onDialSearch={onDialSearch}
    />
  );
};

PhoneNumberContainer.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  defaultMobileCountry: PropTypes.string,
};

PhoneNumberContainer.defaultProps = {
  defaultMobileCountry: undefined,
};

export default PhoneNumberContainer;

import { postJSON } from 'bv-fetch';

export const emailValidation = (email, token) => (
  postJSON('/bv_api/account/email_validation', {
    email,
    token,
  }).catch(() => ({
    error: true,
  }))
);

export const emailAvailability = (email, token) => (
  postJSON('/bv_api/account/email_availability', {
    email,
    token,
  }).catch(() => ({
    error: true,
  }))
);

import { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { useHistory } from 'react-router';
import { v as bvVar } from 'bv';
import { ButtonCard, Button } from 'bv-components';
import withPersonalDetailsSection from 'PersonalDetails/hocs/with_personal_details_section';
import sendVerificationCode from 'PersonalDetails/api/send_verification_code';
import EditButton from '../../utils/edit_button';
import SectionFooter from '../section_footer';
import FailureSubmitModal from '../../failure_submit_modal';

const PhoneDetailsView = ({ phoneNumber, twoFASmsEnabled, phoneNumberVerified }) => {
  let handleVerificationButtonClick;
  let verificationButton = null;
  const history = useHistory();
  const [twoFACodeError, setTwoFACodeError] = useState(null);

  if (!phoneNumberVerified) {
    handleVerificationButtonClick = () => sendVerificationCode().then((response) => {
      if (response.success) {
        history.push('/personal_details/phone_number/complete_mobile_verification');
      } else {
        setTwoFACodeError(true);
      }
    });

    verificationButton = (
      <ButtonCard
        label={t('javascript.personal_details.verify_button')}
        type="personal-details-footer-button"
        onClick={handleVerificationButtonClick}
      />
    );
  } else if (phoneNumberVerified && twoFASmsEnabled) {
    handleVerificationButtonClick = () => {
      history.push('/preferences/verify_password_twofactor?skip_device_manager=true');
    };

    verificationButton = (
      <Button
        label={t('javascript.personal_details.status.2fa_sms_enabled')}
        className="verification-btn"
        onClick={handleVerificationButtonClick}
      />
    );
  }

  return (
    <>
      <dl className="pd-list">
        <dt className="pd-list__label">{t('javascript.personal_details.user_details.mobile')}</dt>
        <dd className="pd-list__value">{phoneNumber}</dd>
        {!twoFASmsEnabled && !bvVar('forbiddenPhoneNumberEditing') && (
          <EditButton
            href="/personal_details/phone_number"
            label={t('javascript.personal_details.edit_button')}
          />
        )}
        <SectionFooter
          verified={phoneNumberVerified}
          verificationButton={verificationButton}
        />
      </dl>
      {twoFACodeError && (
        <FailureSubmitModal
          message={t('javascript.twofa.error.general')}
          onClose={() => setTwoFACodeError(null)}
        />
      )}
    </>
  );
};

PhoneDetailsView.propTypes = {
  phoneNumber: PropTypes.string,
  twoFASmsEnabled: PropTypes.bool,
  phoneNumberVerified: PropTypes.bool,
};

PhoneDetailsView.defaultProps = {
  phoneNumber: '',
  phoneNumberVerified: false,
  twoFASmsEnabled: false,
};

export default withPersonalDetailsSection({
  className: 'phone-details',
})(PhoneDetailsView);

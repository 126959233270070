import { connect } from 'react-redux';
import {
  getOccupation,
  getSalary,
  isRequired,
  isEditable,
} from 'SharedComponents/personal_details/ducks';
import OccupationAndSalaryView from './occupation_and_salary_view';

const mapStateToProps = (state) => ({
  occupation: getOccupation(state),
  salary: getSalary(state),
  required: isRequired(state),
  editable: isEditable(state),
});

export default connect(mapStateToProps)(OccupationAndSalaryView);

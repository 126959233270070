import { t } from 'bv-i18n';
import { postJSON } from 'bv-fetch';

export default (password) => postJSON('/api/password/validate', { password })
  .then(({ success, wrongPassword }) => ({
    success,
    message: wrongPassword ? t('account_form.errors.AC-201') : t('javascript.account_form.errors.general'),
  }))
  .catch(() => ({
    success: false,
    message: t('javascript.account_form.errors.general'),
  }));

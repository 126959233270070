import { ModalWindow } from 'bv-components';
import { t } from 'bv-i18n';
import App from './components/app';
import mobileVerificationReducer from './ducks/mobile_verification';
import emailVerificationReducer from './ducks/email_verification';

const { addReducers } = window.reduxState;

addReducers({
  mobileVerification: mobileVerificationReducer,
  emailVerification: emailVerificationReducer,
});

const PersonalDetailsApp = () => (
  <ModalWindow
    title={t('javascript.personal_details.modal_title')}
    className="personal-details-modal"
  >
    <App />
  </ModalWindow>
);

export default PersonalDetailsApp;

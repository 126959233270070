import PropTypes from 'prop-types';

const SingleDialCode = ({ currentCountry }) => (
  <div className="intl-tel-input allow-dropdown separate-dial-code iti-sdc-4">
    <div className="flag-container">
      <div className="selected-flag" title={`${currentCountry.name} +${currentCountry.dialCode}`}>
        <div className={`iti-flag ${currentCountry.code}`} />
        <div className="selected-dial-code">{`+${currentCountry.dialCode}`}</div>
      </div>
    </div>
  </div>
);

SingleDialCode.propTypes = {
  currentCountry: PropTypes.instanceOf(Object).isRequired,
};

export default SingleDialCode;

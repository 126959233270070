import { accountData } from 'bv';
import startMobileNumberVerification from '../api/start_mobile_number_verification';

export default ({ successResponseHandler, errorResponseHandler }) => {
  accountData().then(({ id }) => {
    startMobileNumberVerification({ accountId: id })
      .then((response) => successResponseHandler(response))
      .catch(errorResponseHandler);
  });
};

import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const FailureSubmitModal = ({ onClose, message }) => (
  <Modal
    danger
    actions={[{
      id: 'ok-btn',
      label: t('close'),
      danger: true,
      close: true,
      onClick: onClose,
    }]}
  >
    <p className="bvs-text-error">
      {message}
    </p>
  </Modal>
);

FailureSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default FailureSubmitModal;

// When this module is loaded we want to add the personalDetails reducer to the state
import reducer from './reducer';

export * from './action_creators';
export * from './selectors';

const { addReducers } = window.reduxState;

addReducers({
  personalDetails: reducer,
});

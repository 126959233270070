import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'underscore';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Spinner } from 'bv-components';
import {
  fetchPersonalDetails as fetchPersonalDetailsThunk,
  unsetPersonalDetailsUpdated as unsetPersonalDetailsUpdatedAction,
  getLoadingPersonalDetails,
} from 'SharedComponents/personal_details/ducks';
import PersonalDetailsPage from './personal_details_page';
import EditAddress from './edit_address/edit_address_redux';
import EditPhoneNumber from './edit_phone_number/edit_phone_number_redux';
import EditOccupationAndSalary from './edit_occupation_and_salary/edit_occupation_and_salary_redux';
import MobileVerification from './mobile_verification';
import { loadingStrategy } from '../selectors/email_verification';

const App = ({
  contentLoading,
  strategyLoading,
  accountDataMissing,
  personalDetailsUpdated,
  fetchPersonalDetails,
  unsetPersonalDetailsUpdated,
}) => {
  useEffect(() => {
    if (accountDataMissing || personalDetailsUpdated) {
      fetchPersonalDetails();
      unsetPersonalDetailsUpdated();
    }
  }, [accountDataMissing, personalDetailsUpdated]);

  if (contentLoading || strategyLoading) return <Spinner />;

  return (
    <Switch>
      <Route
        exact
        path="/personal_details"
        component={PersonalDetailsPage}
      />
      <Route
        exact
        path="/personal_details/phone_number/complete_mobile_verification"
        component={MobileVerification}
      />
      <Route
        exact
        path="/personal_details/phone_number"
        component={EditPhoneNumber}
      />
      <Route
        exact
        path="/personal_details/address"
        component={EditAddress}
      />
      <Route
        exact
        path="/personal_details/occupation_and_salary"
        component={EditOccupationAndSalary}
      />
    </Switch>
  );
};

App.propTypes = {
  contentLoading: PropTypes.bool.isRequired,
  strategyLoading: PropTypes.bool.isRequired,
  fetchPersonalDetails: PropTypes.func.isRequired,
  unsetPersonalDetailsUpdated: PropTypes.func.isRequired,
  accountDataMissing: PropTypes.bool.isRequired,
  personalDetailsUpdated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  contentLoading: getLoadingPersonalDetails(state),
  strategyLoading: loadingStrategy(state),
  accountDataMissing: isEmpty(state.personalDetails.accountData),
  personalDetailsUpdated: state.personalDetails.personalDetailsUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPersonalDetails: compose(dispatch, fetchPersonalDetailsThunk),
  unsetPersonalDetailsUpdated: compose(dispatch, unsetPersonalDetailsUpdatedAction),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Spinner } from 'bv-components';
import withLazyLoad from 'bv-lazy-load';

const OccupationAndSalaryApp = withLazyLoad(<Spinner />)(
  'personalDetails.features.occupationAndSalary',
);

const EditOccupationAndSalaryView = ({ formValues, setPersonalDetailsUpdated }) => {
  const history = useHistory();

  if (formValues && !formValues.required) {
    history.replace('/personal_details');
    return null;
  }

  return (
    <OccupationAndSalaryApp
      formValues={formValues}
      renderInModal
      onSuccessModalClose={() => {
        setPersonalDetailsUpdated();
        history.goBack();
      }}
    />
  );
};

EditOccupationAndSalaryView.propTypes = {
  formValues: PropTypes.shape({
    occupation: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    salary: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
    required: PropTypes.bool,
    editable: PropTypes.bool,
  }),
  setPersonalDetailsUpdated: PropTypes.func.isRequired,
};

EditOccupationAndSalaryView.defaultProps = {
  formValues: undefined,
};

export default EditOccupationAndSalaryView;

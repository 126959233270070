import { t } from 'bv-i18n';
import postMobileNumberValidation from '../api/mobile_number_validation';

const formattedNumber = (phoneNumber) => `+${phoneNumber.dial_code}${phoneNumber.number}`;

export default (values, form) => {
  const { validatedPhoneNumber, phone_number: phoneNumber } = values;
  if (validatedPhoneNumber && validatedPhoneNumber.number === formattedNumber(phoneNumber)) {
    return Promise.resolve(validatedPhoneNumber);
  }

  return postMobileNumberValidation(phoneNumber.number, phoneNumber.country_iso).then(
    (response) => {
      form.change('validatedPhoneNumber', {
        number: formattedNumber(phoneNumber),
        ...response,
      });

      if (response.valid === true) {
        return Promise.resolve(response);
      }

      throw new Error(response.message || t('javascript.account_form.errors.general'));
    },
  );
};

import { connect } from 'react-redux';
import { compose } from 'underscore';
import { setMobileNumberAsVerified } from 'SharedComponents/personal_details/ducks';
import { resendMobileVerificationCode, verifyMobileNumber } from '../../ducks/mobile_verification';
import { getIsLoading } from '../../selectors/mobile_verification';
import MobileVerificationContainer from './mobile_verification_container';

const mapStateToProps = (state) => ({
  loading: getIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  resendMobileVerificationCode: (onSuccessCallback) => (
    dispatch(resendMobileVerificationCode(onSuccessCallback))
  ),
  verifyMobileNumber: (code, onSuccessCallback) => (
    dispatch(verifyMobileNumber(code, onSuccessCallback))
  ),
  setMobileNumberVerified: compose(dispatch, setMobileNumberAsVerified),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerificationContainer);

import { t } from 'bv-i18n';

export default (allowedSymbols) => (
  (value) => {
    if (!value) return undefined;

    const nonAlphanumericChars = value.match(/[^a-zA-Z0-9]/g) || [];
    return nonAlphanumericChars.every((char) => allowedSymbols.includes(char))
      ? undefined
      : t('javascript.account_form.errors.has_disallowed_chars');
  }
);

/* eslint prefer-promise-reject-errors: off */
import {
  asyncMobileNumberValidation,
  asyncEmailAvailable,
  asyncEmailValidation,
  asyncPostcodeValidation,
} from 'validations';
import { getValidationFunction, getValidationsMethods } from 'validation-helpers';
import { property, isObject } from 'underscore';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

// Emoji char codes
const emojis = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

const getNationalityValidationFunction = (validations) => {
  const validationFns = getValidationsMethods(validations);

  return (formValue, allFormValues) => {
    for (let i = 0; i < validationFns.length; i += 1) {
      const error = validationFns[i](formValue, allFormValues);
      if (error) return error;
    }
    if (
      !isObject(formValue)
      || !isObject(allFormValues.nationality)
      || formValue.description !== allFormValues.nationality?.description
    ) {
      return t('javascript.account_form.errors.choose_from_dropdown.nationality');
    }

    return undefined;
  };
};

export const getValidationFnForField = (type, validations) => {
  switch (type) {
    case 'nationality':
      return getNationalityValidationFunction(validations);
    default:
      return getValidationFunction(validations);
  }
};

export const escapeInput = (text) => text.replace(emojis, '');

const validateNumber = ({ phone_number: phoneNumber }) => {
  const config = bvVar('countriesAllowEmptyMobileNumber');
  const countriesAllowEmptyMobileNumber = (
    (config[bvVar('brandId')] && config[bvVar('brandId')][bvVar('locale')]) || config.default
  );

  return (
    !countriesAllowEmptyMobileNumber.includes('*')
    && countriesAllowEmptyMobileNumber.find((c) => (
      c.toUpperCase() === phoneNumber.country_iso.toUpperCase()
    )) === undefined
  );
};

export const mobileNumberValidation = (values, form) => {
  const registeredFields = form.getRegisteredFields();
  return new Promise((resolve) => {
    if (
      !registeredFields.includes('phone_number')
      || (!property(['phone_number', 'number'])(values) && !validateNumber(values))
    ) {
      resolve();
    } else if (!property(['phone_number', 'number'])(values)) {
      resolve({ phone_number: t('javascript.account_form.errors.mandatory.phone_number') });
    } else {
      asyncMobileNumberValidation(values, form)
        .then(({ valid, message }) => {
          if (!valid && message) resolve({ phone_number: message });
          resolve();
        })
        .catch((error) => {
          resolve({ phone_number: error.message });
        });
    }
  });
};

const asyncFieldValidation = (validationFn, field) => (values, form) => {
  const registeredFields = form.getRegisteredFields();
  return new Promise((resolve) => {
    if (!registeredFields.includes(field) || !values[field]) {
      resolve();
    } else {
      validationFn(values, form)
        .then((props) => {
          const { valid, message } = props;
          if (!valid && message) resolve({ [field]: message });
          resolve();
        })
        .catch((error) => {
          resolve({ [field]: error.message });
        });
    }
  });
};

export const emailAvailableValidation = asyncFieldValidation(asyncEmailAvailable, 'email');
export const postCodeValidation = asyncFieldValidation(asyncPostcodeValidation, 'postcode');
export const emailValidation = asyncFieldValidation(asyncEmailValidation, 'email');

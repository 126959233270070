import { postJSON } from 'bv-fetch';

export default (mobileNumber, countryIso) => (
  postJSON('/bv_api/account/mobile_number',
    {
      phone_number: mobileNumber,
      country_iso: countryIso,
    })
    .catch(() => (
      {
        error: true,
      }
    ))
);

import { connect } from 'react-redux';
import {
  setPersonalDetailsUpdated,
  getComplianceData,
} from 'SharedComponents/personal_details/ducks';
import EditOccupationAndSalaryView from './edit_occupation_and_salary_view';

const mapStateToProps = (state) => ({
  formValues: getComplianceData(state),
});

export default connect(mapStateToProps, {
  setPersonalDetailsUpdated,
})(EditOccupationAndSalaryView);

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { escapeInput } from 'Registration/helpers/validations';
import { SmallSpinner } from 'bv-components';
import PlaceHolder from './placeholder';

const Input = forwardRef((props, ref) => {
  const {
    input, label, type, disabled,
    meta: {
      touched,
      error,
      active,
      asyncValidating,
    },
  } = props;

  const inputClassNames = classnames('bvs-form-control', {
    error: error && touched,
    'with-value': input.value && input.value !== '',
    active,
  });

  const onChange = ({ target: { value } }) => input.onChange(escapeInput(value));

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        className={inputClassNames}
        type={type}
        id={input.name}
        name={input.name}
        disabled={disabled}
        onChange={onChange}
        ref={ref}
      />
      <PlaceHolder htmlFor={input.name} label={label} />
      {error && <div className="bvs-form-error-msg">{error}</div>}
      {asyncValidating && <div className="account-form-validations-spinner"><SmallSpinner /></div>}
    </div>
  );
});

Input.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default Input;

import {
  CODE_WAS_SENT_TOO_MANY_TIMES,
  SUCCESS,
} from './verification_constants';

export default (status, push, setMobileNumberVerified) => {
  if (status === SUCCESS || status === CODE_WAS_SENT_TOO_MANY_TIMES) {
    if (status === SUCCESS) setMobileNumberVerified();

    setTimeout(() => push('/personal_details'), 1000);
  }
};

import AddressDetails from './sections/address_details';
import EmailDetails from './sections/email_details/email_details_redux';
import GeneralDetails from './sections/general_details';
import PhoneDetails from './sections/phone_details/phone_details_redux';
import OccupationAndSalaryDetails from './sections/occupation_and_salary/occupation_and_salary_redux';
import NationalityDetails from './sections/nationality_details/nationality_redux';

const PersonalDetailsPage = () => (
  <>
    <GeneralDetails />
    <PhoneDetails />
    <NationalityDetails />
    <EmailDetails />
    <OccupationAndSalaryDetails />
    <AddressDetails />
  </>
);

export default PersonalDetailsPage;

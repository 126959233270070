import { t } from 'bv-i18n';

export default (validations, errorMessage) => (
  (value, allValues) => {
    const { minLength, countries } = validations;

    if (!value || value.trim().length >= minLength || countries.includes(allValues.country)) {
      return undefined;
    }

    return errorMessage || t('javascript.account_form.errors.minimum_characters', { min_length: minLength });
  }
);

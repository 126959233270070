import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SmallSpinner } from 'bv-components';
import PlaceHolder from './placeholder';

const CountryZone = ({
  input, label, countryZones, meta: {
    touched, error, active, asyncValidating,
  },
}) => {
  const inputClassNames = classnames('bvs-form-control with-value', {
    error: error && touched,
    active,
  });

  return (
    <div className="bvs-form-group">
      <select
        {...input}
        key={input.name}
        id={`account_${input.name}`}
        className={inputClassNames}
      >
        {!input.value && <option value="">{label}</option>}
        {countryZones.map(({ id, description }) => (
          <option key={id} id={id} value={id}>{description}</option>
        ))}
      </select>
      <PlaceHolder htmlFor={input.name} label={label} />
      {error && <div className="bvs-form-error-msg">{error}</div>}
      {asyncValidating && <div className="account-form-validations-spinner"><SmallSpinner /></div>}
    </div>
  );
};

CountryZone.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
};

export default CountryZone;

import { createSelector } from 'reselect';

const personalDetailsDomain = (state) => state.personalDetails;

export const generalDetails = (state) => state.personalDetails
  && state.personalDetails.accountData.generalDetails;

export const accountEmail = (state) => state.personalDetails
  && state.personalDetails.accountData.email;

export const getAddressFields = (state) => state.personalDetails.accountData.addressFields || [];

export const getPersonalDetailsFields = (state) => (
  state.personalDetails.accountData.personalDetailsFields || []
);

export const getLoadingPersonalDetails = (state) => state.personalDetails.fetchingAccountDetails;

export const countryName = (state) => state.personalDetails
  && state.personalDetails.accountData.countryName;

export const getComplianceData = (state) => state.personalDetails.accountData.complianceData;

export const getCanUpdatePhoneNumber = (state) => (
  state.personalDetails.accountData.canUpdatePhoneNumber || false
);

export const getSalary = createSelector(
  [getComplianceData], (complianceData) => {
    if (complianceData && complianceData.salary) {
      return complianceData.salary.label;
    }

    return null;
  },
);

export const getOccupation = createSelector(
  [getComplianceData], (complianceData) => {
    if (complianceData && complianceData.occupation) {
      return complianceData.occupation.label;
    }

    return null;
  },
);

export const isRequired = createSelector(
  [getComplianceData], (complianceData) => {
    if (complianceData) {
      return complianceData.required;
    }

    return null;
  },
);

export const isEditable = createSelector(
  [getComplianceData], (complianceData) => {
    if (complianceData) {
      return complianceData.editable;
    }

    return null;
  },
);

export const getCountryZones = createSelector(
  personalDetailsDomain,
  (subState) => subState.accountData.countryZones,
);

export const getCountryZone = createSelector(
  getAddressFields,
  getCountryZones,
  (address, zones) => {
    const zoneField = address.find((field) => ['county_id', 'state_id'].includes(field.name));
    if (zoneField) {
      const zone = zones.find((z) => z.id === zoneField.value);
      return zone ? zone.description : '';
    }
    return '';
  },
);

export const getInitialAddressValues = createSelector(
  getAddressFields,
  (fields) => fields.reduce((initialValues, field) => ({
    ...initialValues,
    [field.name]: field.value,
  }), {}),
);

export const getTwoFASmsEnabled = createSelector(
  personalDetailsDomain,
  (subState) => subState.accountData.twoFASmsEnabled,
);

export const getDetailedPhoneNumber = createSelector(
  personalDetailsDomain,
  (subState) => subState.accountData.detailedPhoneNumber,
);

export const getLoadingMobileCountries = createSelector(
  personalDetailsDomain,
  (subState) => subState.loadingMobileCountries,
);

export const getMobileNumberCountries = createSelector(
  personalDetailsDomain,
  (subState) => subState.mobileNumberCountries,
);

import { t } from 'bv-i18n';
import { getCaptchaService } from 'bv-services';
import { emailValidation } from '../api/email_validation';

export default async (values, form) => {
  const { email, emailValidated } = values;

  if (emailValidated && emailValidated.value === email) {
    return Promise.resolve(emailValidated);
  }

  const token = await getCaptchaService().execute('email_validation');

  return emailValidation(email, token).then(
    (response) => {
      form.change('emailValidated', { ...response, value: email });

      if (response.valid === true) {
        return Promise.resolve(true);
      }

      throw new Error(response.message || t('javascript.account_form.errors.general'));
    },
  );
};

import { t } from 'bv-i18n';
import validateObject from '../helpers/validate_object';

const validString = (max) => (value) => value.length <= max;

export default (maxLength, errorMessage) => (
  (value) => {
    const message = errorMessage || t('javascript.account_form.errors.maximum_characters', { max_length: maxLength });
    if (value === undefined || value === null) return undefined;

    switch (typeof value) {
      case 'object':
        return value && validateObject(value, validString(maxLength)) ? undefined : message;
      default:
        return validString(maxLength)(value) ? undefined : message;
    }
  }
);

import { locale } from 'bv-i18n';
import { getCookie } from 'bv';

export const getCountryByCode = (countries, code) => countries.find((c) => (
  c.code.toLowerCase() === code.toLowerCase()
));

export const getInitCountry = (inputValue, countries, defaultMobileCountry) => {
  if (inputValue && inputValue.country_iso) {
    const country = getCountryByCode(countries, inputValue.country_iso);
    if (country) return country;
  }

  // 1st get country by IP Address if available
  const cookie = getCookie('geo_country_code');
  if (cookie && cookie.trim() !== 'false') {
    const country = getCountryByCode(countries, cookie.trim());
    if (country) return country;
  }

  // 2nd by locale if available
  const shortLocale = locale().split('-')[1].toLowerCase();
  const country = getCountryByCode(countries, shortLocale);
  if (country) return country;

  // 3rd use default country from registration settings on CMS
  if (defaultMobileCountry) {
    const defaultCountry = getCountryByCode(countries, defaultMobileCountry);
    if (defaultCountry) return defaultCountry;
  }

  // 4th get first country from available countries
  if (countries.length) return countries[0];

  // default
  return {
    code: 'gb',
    name: 'United Kingdom',
    dialCode: '44',
  };
};

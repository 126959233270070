import PropTypes from 'prop-types';
import { Button } from 'bv-components';

const EditButton = ({ href, label }) => (
  <Button
    href={href}
    className="pd-list__btn-edit"
    noClass
    type="account-overview-personal-details-edit"
  >
    {label}
  </Button>
);

EditButton.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default EditButton;

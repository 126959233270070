import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Card, Icon } from 'bv-components';
import { t } from 'bv-i18n';
import submitHandler from 'SharedComponents/personal_details/api/update_personal_details';
import SubmitButton from '../utils/submit_button';
import PhoneNumber from '../phone_number';
import SuccessSubmitModal from '../success_submit_modal';

const EditPhoneNumberView = ({
  countries, twoFASmsEnabled, detailedPhoneNumber, onSuccessModalClose,
}) => (
  <div className="personal_details__form">
    <Form
      onSubmit={submitHandler}
      initialValues={{ phone_number: detailedPhoneNumber }}
    >
      {({
        pristine, submitting, handleSubmit, submitSucceeded, hasValidationErrors,
      }) => (
        <form>
          <Card>
            <Field
              name="phone_number"
              component={PhoneNumber}
              label={t('javascript.kyc.callback.mobile_number')}
              countries={countries}
            />
            {!twoFASmsEnabled && (
              <p className="personal_details__phone-status">
                <Icon id="lock-disabled" className="personal_details__phone-status-icon" />
                <span>{t('javascript.personal_details.status.2fa_disabled')}</span>
              </p>
            )}
          </Card>
          <SubmitButton
            disabled={pristine || hasValidationErrors || submitting}
            onClick={handleSubmit}
            submitting={submitting}
          />
          {submitSucceeded && (
            <SuccessSubmitModal
              onClose={onSuccessModalClose}
              message={t('your_mobile_preferences_have_been_updated')}
            />
          )}
        </form>
      )}
    </Form>
  </div>
);

EditPhoneNumberView.propTypes = {
  countries: PropTypes.arrayOf(Object).isRequired,
  twoFASmsEnabled: PropTypes.bool.isRequired,
  onSuccessModalClose: PropTypes.func.isRequired,
  detailedPhoneNumber: PropTypes.instanceOf(Object).isRequired,
};

export default EditPhoneNumberView;

import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const wrongCodeText = (triesLeft) => (
  (triesLeft === null)
    ? t('javascript.twofa.enable_failed.wrong_invalid_code_generic')
    : t('javascript.twofa.enable_failed.wrong_invalid_code', { number_attempts_left: triesLeft })
);

const WrongFeedback = ({ message, status, triesLeft }) => (
  <p className="send-verify-code__wrong-attempt">
    { status === 'wrong_code' && wrongCodeText(triesLeft) }
    { status === 'failed' && (message || t('javascript.twofa.error.general')) }
    { status === 'code_was_sent_too_many_times' && t('javascript.twofa.error.failed_sms_many_times') }
  </p>
);

WrongFeedback.propTypes = {
  triesLeft: PropTypes.number,
  status: PropTypes.string,
  message: PropTypes.string,
};

WrongFeedback.defaultProps = {
  triesLeft: -1,
  status: '',
  message: null,
};

export default WrongFeedback;

import { t } from 'bv-i18n';

const REGEX = /^[0-9]{13}$/;

const validateThaiIDCardNumber = () => (
  (value) => {
    if (!REGEX.test(value)) {
      return t('javascript.error_identity_number_format');
    }

    let i; let sum = 0;
    for ((i = 0), (sum = 0); i < 12; i += 1) {
      sum += parseInt(value.charAt(i), 10) * (13 - i);
    }

    const checkSum = (11 - (sum % 11)) % 10;

    if (checkSum === parseInt(value.charAt(12), 10)) {
      return undefined;
    }

    return t('javascript.error_identity_number_format');
  }
);

const validateThaiIDCardNumberNotMandatory = () => (
  (value) => (value ? validateThaiIDCardNumber()(value) : undefined)
);

export {
  validateThaiIDCardNumber,
  validateThaiIDCardNumberNotMandatory,
};

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const CodeInput = forwardRef((props, ref) => {
  const {
    maxCodeLength,
    value,
    handleCodeInputChange,
    handleCodeInputKeyPress,
  } = props;

  return (
    <div className="send-verify-code__input-container">
      <input
        maxLength={maxCodeLength}
        value={value}
        onChange={handleCodeInputChange}
        onKeyUp={handleCodeInputKeyPress}
        type="number"
        ref={ref}
        name="code"
        inputMode="numeric"
        pattern="\d*"
        autoComplete="off"
      />
    </div>
  );
});

CodeInput.propTypes = {
  maxCodeLength: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  handleCodeInputChange: PropTypes.func.isRequired,
  handleCodeInputKeyPress: PropTypes.func.isRequired,
};

export default CodeInput;

import PropTypes from 'prop-types';
import WrongFeedback from './wrong_feedback';

const CodeStatus = ({
  triesLeft, status, isInvalidStatus, isValidStatus,
}) => {
  if (isInvalidStatus(status)) return <WrongFeedback status={status} triesLeft={triesLeft} />;
  if (isValidStatus(status)) return <div className="send-verify-code__success-icon" />;

  return null;
};

CodeStatus.propTypes = {
  status: PropTypes.string.isRequired,
  triesLeft: PropTypes.number.isRequired,
  isInvalidStatus: PropTypes.func.isRequired,
  isValidStatus: PropTypes.func.isRequired,
};

export default CodeStatus;

import {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { ModalWindowContext } from 'bv-contexts';
import classnames from 'classnames';
import SingleDialCode from './single_dial_code';

const DialCodes = ({
  showDialCodes, onDialListClick, onDialListBlur, countries, currentCountry,
  onCountryClick, countryDialSearch, onDialSearch,
}) => {
  const { scrollableRef } = useContext(ModalWindowContext);
  const inputRef = useRef();
  const [dropUpCountryList, setDropUpCountryList] = useState(false);
  const [scrollableOffset, setScrollableOffset] = useState(0);

  useEffect(() => {
    if (scrollableRef && scrollableRef.current) {
      setScrollableOffset(scrollableRef.current.getBoundingClientRect().top);
    }
  });

  useEffect(() => {
    if (showDialCodes) {
      if (
        scrollableRef && scrollableRef.current && inputRef && inputRef.current
      ) {
        const elementOffset = inputRef.current.getBoundingClientRect().top;
        const { offsetHeight } = scrollableRef.current;

        const { height: windowHeight } = window.screen;
        const modalContainerscrollPercentage = (
          (elementOffset - scrollableOffset) / offsetHeight
        ) * 100;

        if (elementOffset && elementOffset < 150) {
          setDropUpCountryList(false);
        } else if (elementOffset && elementOffset > windowHeight - 100) {
          setDropUpCountryList(true);
        } else {
          setDropUpCountryList(modalContainerscrollPercentage > 50);
        }
      }

      const element = document.querySelector(`.country.${currentCountry.code.toLowerCase()}`);
      if (element) document.querySelector('.country-list').scrollTop = element.offsetTop;
    }
  }, [showDialCodes]);

  const containerClasses = classnames(
    'intl-tel-input allow-dropdown separate-dial-code iti-sdc-4', {
      expanded: showDialCodes,
    },
  );

  const countryListClasses = classnames(
    'country-list', {
      hide: !showDialCodes,
      dropup: dropUpCountryList,
    },
  );

  if (countries.length === 1) {
    return <SingleDialCode currentCountry={currentCountry} />;
  }

  const filteredCountries = countryDialSearch === null ? countries : countries.filter((c) => (
    c.dialCode.indexOf(countryDialSearch) !== -1
  ));

  const onCountryMouseDown = (event, country) => {
    event.preventDefault();
    onCountryClick(country);
  };

  const onFlagContainerMouseDown = (event) => {
    event.preventDefault();
    onDialListClick();
  };

  return (
    <div className={containerClasses} onBlur={onDialListBlur}>
      <div className="flag-container" onMouseDown={onFlagContainerMouseDown}>
        <div className="selected-flag" title={`${currentCountry.name} +${currentCountry.dialCode}`}>
          <div className={`iti-flag ${currentCountry.code}`} />
          <div className="selected-dial-code">+</div>
          <input
            ref={inputRef}
            className="intl-tel-search-prefix"
            value={countryDialSearch === null ? currentCountry.dialCode : countryDialSearch}
            onChange={onDialSearch}
          />
          <div className={`iti-arrow ${showDialCodes ? 'up' : ''}`} />
        </div>
        <ul className={countryListClasses}>
          {filteredCountries.map((country) => (
            <li
              key={country.code}
              className={`country ${country.code}`}
              onMouseDown={(evt) => onCountryMouseDown(evt, country)}
            >
              <div className="flag-box">
                <div className={`iti-flag ${country.code.toLowerCase()}`} />
              </div>
              <span className="country-name">{country.name}</span>
              <span className="dial-code">{`+${country.dialCode}`}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

DialCodes.propTypes = {
  countries: PropTypes.arrayOf(Object).isRequired,
  showDialCodes: PropTypes.bool.isRequired,
  currentCountry: PropTypes.instanceOf(Object).isRequired,
  onDialListClick: PropTypes.func.isRequired,
  onDialListBlur: PropTypes.func.isRequired,
  onCountryClick: PropTypes.func.isRequired,
  onDialSearch: PropTypes.func.isRequired,
  countryDialSearch: PropTypes.string,
};

DialCodes.defaultProps = {
  countryDialSearch: null,
};

export default DialCodes;

const validateFullDate = (parsedDate, arrayDate) => (
  parsedDate.getUTCDate() === parseInt(arrayDate[2], 10)
  && (parsedDate.getUTCMonth() + 1) === parseInt(arrayDate[1], 10)
  && parsedDate.getUTCFullYear() === parseInt(arrayDate[0], 10)
);

export default (errorMessage) => (
  (value) => {
    const parsedDate = new Date(value);
    if (Number.isNaN(Number(parsedDate.getDate()))) return errorMessage;

    return validateFullDate(parsedDate, value.split('-')) ? undefined : errorMessage;
  }
);

import PropTypes from 'prop-types';
import TrustCheckbox from './trust_checkbox';

const IntroText = ({
  showTrustCheckbox, checked, onChange, icon, description, enterCode,
}) => (
  <>
    <div className="send-verify-code__header">
      { icon }
      <p>{ description }</p>
    </div>
    { showTrustCheckbox && <TrustCheckbox checked={checked} onChange={onChange} />}
    <p className="send-verify-code__title">{ enterCode }</p>
  </>
);

IntroText.propTypes = {
  description: PropTypes.string,
  enterCode: PropTypes.string,
  showTrustCheckbox: PropTypes.bool.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.instanceOf(Object),
};

IntroText.defaultProps = {
  description: null,
  enterCode: null,
  icon: null,
  checked: false,
  onChange: () => {},
};

export default IntroText;

import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Card } from 'bv-components';

const NationalityView = ({
  nationality,
}) => {
  if (!nationality) return null;

  return (
    <Card className="nationality-details">
      <dl className="pd-list">
        <dt className="pd-list__label">
          {t('javascript.personal_details.nationality')}
        </dt>
        <dd className="pd-list__value">{nationality.description}</dd>
      </dl>
    </Card>
  );
};

NationalityView.propTypes = {
  nationality: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string.isRequired,
    isoCode: PropTypes.string,
  }),
};

NationalityView.defaultProps = {
  nationality: null,
};

export default NationalityView;

import { isEmpty } from 'underscore';
import {
  FETCH_PERSONAL_DETAILS_COMPLETE,
  FETCH_PERSONAL_DETAILS_INIT,
  SET_PERSONAL_DETAILS_UPDATED,
  UNSET_PERSONAL_DETAILS_UPDATED,
  SET_MOBILE_NUMBER_VERIFIED,
  FETCH_MOBILE_COUNTRIES_INIT,
  FETCH_MOBILE_COUNTRIES_COMPLETE,
} from './action_types';
import doFetchPersonalDetails from '../api/fetch_personal_details';
import fetchMobileCountries from '../api/fetch_mobile_number_countries';

export const fetchPersonalDetailsInit = () => ({
  type: FETCH_PERSONAL_DETAILS_INIT,
});

export const fetchPersonalDetailsComplete = (accountData) => ({
  type: FETCH_PERSONAL_DETAILS_COMPLETE,
  accountData,
});

export const setPersonalDetailsUpdated = () => ({
  type: SET_PERSONAL_DETAILS_UPDATED,
});

export const unsetPersonalDetailsUpdated = () => ({
  type: UNSET_PERSONAL_DETAILS_UPDATED,
});

export const fetchPersonalDetails = () => (dispatch) => {
  dispatch(fetchPersonalDetailsInit());

  doFetchPersonalDetails().then((details) => {
    dispatch(fetchPersonalDetailsComplete(details));
  });
};

export const setMobileNumberAsVerified = () => ({
  type: SET_MOBILE_NUMBER_VERIFIED,
});

export const fetchMobileNumberCountriesInit = () => ({
  type: FETCH_MOBILE_COUNTRIES_INIT,
});

export const fetchMobileNumberCountriesComplete = (countries) => ({
  type: FETCH_MOBILE_COUNTRIES_COMPLETE,
  countries,
});

export const fetchMobileNumberCountries = () => (dispatch, getState) => {
  const { loadingMobileCountries, mobileNumberCountries } = getState().personalDetails;
  if (loadingMobileCountries || !isEmpty(mobileNumberCountries)) return;

  dispatch(fetchMobileNumberCountriesInit());

  fetchMobileCountries().then((countries) => {
    dispatch(fetchMobileNumberCountriesComplete(countries));
  });
};

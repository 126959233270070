import PropTypes from 'prop-types';
import classNames from 'classnames';

const Dot = ({ value, status }) => {
  const styleNames = classNames('send-verify-code__feedback-dot', {
    'without-background': value.length > 0,
    'is-success': status === 'success',
    'is-failed': ['failed', 'wrong_code'].includes(status),
  });
  return <div className={styleNames}>{value}</div>;
};

Dot.propTypes = {
  value: PropTypes.string,
  status: PropTypes.string,
};

Dot.defaultProps = {
  value: '',
  status: '',
};

export default Dot;

import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';
import postcodeValidation from '../api/postcode_validation';

export default (values, form) => {
  const excludeNIrelandPostcode = bvVar('postcodesToExclude').north_ireland;
  const isBrandAvailable = excludeNIrelandPostcode[bvVar('brandId')] !== undefined ? excludeNIrelandPostcode[bvVar('brandId')] : excludeNIrelandPostcode.default;
  if (!isBrandAvailable) return Promise.resolve(true);

  const { postcode, postcodeValidated } = values;

  if (postcodeValidated && postcodeValidated.value === postcode) {
    return Promise.resolve(postcodeValidated);
  }

  return postcodeValidation(postcode.toUpperCase()).then(
    (response) => {
      form.change('postcodeValidated', { ...response, value: postcode });

      if (response.valid === true) {
        return Promise.resolve(true);
      }

      throw new Error(response.message || t('javascript.account_form.errors.general'));
    },
  );
};

import { connect } from 'react-redux';
import { compose } from 'underscore';
import {
  getTwoFASmsEnabled,
  getDetailedPhoneNumber,
  setPersonalDetailsUpdated,
  fetchMobileNumberCountries,
  getMobileNumberCountries,
} from 'SharedComponents/personal_details/ducks';
import EditPhoneNumberContainer from './edit_phone_number_container';

const mapStateToProps = (state) => ({
  countries: getMobileNumberCountries(state),
  detailedPhoneNumber: getDetailedPhoneNumber(state),
  twoFASmsEnabled: getTwoFASmsEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchMobileNumberCountries: compose(dispatch, fetchMobileNumberCountries),
  setPersonalDetailsUpdated: compose(dispatch, setPersonalDetailsUpdated),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPhoneNumberContainer);

import { isEmpty } from 'underscore';
import fetchMobileCountries from 'SharedComponents/personal_details/api/fetch_mobile_number_countries';
import sendMobileVerificationCodeService from '../services/send_mobile_verification_code';
import verifyMobileNumberService from '../services/verify_mobile_number';

const RESEND_MOBILE_VERIFICATION_CODE_INIT = 'personalDetails/mobileVerification/RESEND_CODE_INIT';
const RESEND_MOBILE_VERIFICATION_CODE_COMPLETE = 'personalDetails/mobileVerification/RESEND_CODE_COMPLETE';
const VERIFY_MOBILE_INIT = 'personalDetails/mobileVerification/VERIFY_MOBILE_INIT';
const VERIFY_MOBILE_COMPLETE = 'personalDetails/mobileVerification/VERIFY_MOBILE_COMPLETE';
const FETCH_MOBILE_COUNTRIES_INIT = 'personalDetails/mobileVerification/FETCH_MOBILE_COUNTRIES_INIT';
const FETCH_MOBILE_COUNTRIES_COMPLETE = 'personalDetails/mobileVerification/FETCH_MOBILE_COUNTRIES_COMPLETE';

export const resendMobileVerificationCodeInit = () => ({
  type: RESEND_MOBILE_VERIFICATION_CODE_INIT,
});

export const resendMobileVerificationCodeComplete = () => ({
  type: RESEND_MOBILE_VERIFICATION_CODE_COMPLETE,
});

export const verifyMobileInit = () => ({
  type: VERIFY_MOBILE_INIT,
});

export const verifyMobileComplete = () => ({
  type: VERIFY_MOBILE_COMPLETE,
});

export const fetchMobileNumberCountriesInit = () => ({
  type: FETCH_MOBILE_COUNTRIES_INIT,
});

export const fetchMobileNumberCountriesComplete = (countries) => ({
  type: FETCH_MOBILE_COUNTRIES_COMPLETE,
  countries,
});

export const resendMobileVerificationCode = (onSuccessCallback) => (dispatch) => {
  dispatch(resendMobileVerificationCodeInit());

  const successResponseHandler = (response) => {
    dispatch(resendMobileVerificationCodeComplete());
    onSuccessCallback(response);
  };

  const errorResponseHandler = () => dispatch(resendMobileVerificationCodeComplete());

  sendMobileVerificationCodeService({ successResponseHandler, errorResponseHandler });
};

export const verifyMobileNumber = (code, onSuccessCallback) => (dispatch) => {
  dispatch(verifyMobileInit());

  const successResponseHandler = (response) => {
    dispatch(verifyMobileComplete());
    onSuccessCallback(response);
  };

  const errorResponseHandler = () => dispatch(verifyMobileComplete());

  verifyMobileNumberService({ code, successResponseHandler, errorResponseHandler });
};

export const fetchMobileNumberCountries = () => (dispatch, getState) => {
  const personalDetailsDomain = getState().personalDetails;
  if (personalDetailsDomain.loadingMobileCountries
    || !isEmpty(personalDetailsDomain.mobileNumberCountries)) return;

  dispatch(fetchMobileNumberCountriesInit());

  fetchMobileCountries().then((countries) => {
    dispatch(fetchMobileNumberCountriesComplete(countries));
  });
};

const initialState = {
  mobileVerificationCodeIsResending: false,
  mobileIsVerifying: false,
  loadingMobileCountries: false,
  mobileNumberCountries: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RESEND_MOBILE_VERIFICATION_CODE_INIT:
      return {
        ...state,
        mobileVerificationCodeIsResending: true,
      };
    case RESEND_MOBILE_VERIFICATION_CODE_COMPLETE:
      return {
        ...state,
        mobileVerificationCodeIsResending: false,
      };
    case VERIFY_MOBILE_INIT:
      return {
        ...state,
        mobileIsVerifying: true,
      };
    case VERIFY_MOBILE_COMPLETE:
      return {
        ...state,
        mobileIsVerifying: false,
      };
    case FETCH_MOBILE_COUNTRIES_INIT:
      return {
        ...state,
        loadingMobileCountries: true,
      };
    case FETCH_MOBILE_COUNTRIES_COMPLETE:
      return {
        ...state,
        loadingMobileCountries: false,
        mobileNumberCountries: action.countries,
      };
    default:
      return state;
  }
};

import PropTypes from 'prop-types';

const PlaceHolder = ({ label, htmlFor }) => (
  <label className="bvs-form-control-placeholder" htmlFor={htmlFor}>{label}</label>
);

PlaceHolder.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default PlaceHolder;

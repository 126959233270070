import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import CodeSentFeedback from './code_sent_feedback';

const SendAgain = ({
  method, control, wasCodeResent, cooldown,
}) => {
  if (method === 'totp') return null;

  return (
    <div className="send-verify-code__send-again">
      <p className="send-verify-code__send-again-title">{ t('javascript.twofa.send_code_again.title') }</p>
      { control }
      { wasCodeResent && <CodeSentFeedback cooldown={cooldown} /> }
    </div>
  );
};

SendAgain.propTypes = {
  method: PropTypes.string,
  wasCodeResent: PropTypes.bool.isRequired,
  cooldown: PropTypes.number.isRequired,
  control: PropTypes.instanceOf(Object).isRequired,
};

SendAgain.defaultProps = {
  method: null,
};

export default SendAgain;

import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const SendAgainControl = ({ disabled, handleClick }) => {
  if (disabled) return null;

  return (
    <Button
      secondary
      inverse
      label={t('javascript.twofa.send_code_again.button')}
      onClick={handleClick}
    />
  );
};

SendAgainControl.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SendAgainControl;

import validateObject from '../helpers/validate_object';

const REGEX = /[A-z0-9\u00C0-\u00ff]+/g;
const validString = (value) => !(value.match(REGEX));

export default (errorMessage) => (
  (value) => {
    if (value === undefined || value === null) return undefined;

    switch (typeof value) {
      case 'object':
        return validateObject(value, validString) ? undefined : errorMessage;
      default:
        return validString(value) ? undefined : errorMessage;
    }
  }
);

/* eslint no-useless-escape:"off" */
import { t } from 'bv-i18n';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default (errorMessage) => (
  (value) => (
    EMAIL_REGEX.exec(value) ? undefined : errorMessage || t('javascript.account_form.errors.enter_valid_email')
  )
);

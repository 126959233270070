import { connect } from 'react-redux';
import PhoneDetailsView from './phone_details_view';

const mapStateToProps = (state) => {
  const { personalDetails: { accountData } } = state;

  return {
    phoneNumber: accountData.phoneNumber,
    twoFASmsEnabled: accountData.twoFASmsEnabled,
    phoneNumberVerified: accountData.phoneNumberVerified,
  };
};

export default connect(mapStateToProps)(PhoneDetailsView);

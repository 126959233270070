import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PhoneNumber } from 'registration-common/components';

const PhoneNumberRedux = ({ countries, ...rest }) => (
  <PhoneNumber
    {...rest}
    countries={countries}
  />
);

PhoneNumberRedux.propTypes = {
  countries: PropTypes.arrayOf(Object).isRequired,
};

const mapStateToProps = (state) => state.requestCallback;

export default connect(mapStateToProps)(PhoneNumberRedux);

import { omit } from 'underscore';
import { t } from 'bv-i18n';

const escapeString = (string) => string.replace(/([-/\\^$*+?.()|[\]{}])/g, '\\$1');

const errorsPrefix = 'javascript.account_form.errors';
const passwordValidations = {
  minSize: ({ minSize }, value) => ({
    valid: value.trim().length >= minSize,
    text: t(`${errorsPrefix}.minimum_characters`, { min_length: minSize }),
  }),
  maxSize: ({ maxSize }, value) => ({
    valid: value.trim().length <= maxSize,
    text: t(`${errorsPrefix}.maximum_characters`, { max_length: maxSize }),
  }),
  minLetters: ({ minLetters }, value) => ({
    valid: (value.match(/[a-zA-Z]/g) || []).length >= minLetters,
    text: t(`${errorsPrefix}.password.min_letter_char`),
  }),
  minLowercased: ({ minLowercased }, value) => ({
    valid: (value.match(/[a-z]/g) || []).length >= minLowercased,
    text: t(`${errorsPrefix}.password.min_lowercase_char`),
  }),
  minUppercased: ({ minUppercased }, value) => ({
    valid: (value.match(/[A-Z]/g) || []).length >= minUppercased,
    text: t(`${errorsPrefix}.password.min_uppercase_char`),
  }),
  minDigits: ({ minDigits }, value) => ({
    valid: (value.match(/[0-9]/g) || []).length >= minDigits,
    text: t(`${errorsPrefix}.password.min_number_char`),
  }),
  minSymbols: ({ minSymbols, allowedSymbols }, value) => ({
    valid: (value.match(new RegExp(`[${escapeString(allowedSymbols)}]`)) || []).length >= minSymbols,
    text: t(`${errorsPrefix}.password.min_special_char`),
  }),
};

export default (validationRules) => (
  (value) => (
    Object.keys(omit(validationRules, ['allowedSymbols'])).reduce((accumulator, key) => (
      validationRules[key] && validationRules[key] > 0 ? [
        ...accumulator,
        {
          ...passwordValidations[key](validationRules, value || ''),
          type: key,
        },
      ] : accumulator
    ), [])
  )
);
